.button-about {
  display: block;
  width: 170px;
  height: 2rem;
  background-color: $aquamarine;
  border-radius: 3px; //design value
  box-shadow: 0 1px 4px rgba(64, 212, 126, 0.3); //design value
  color: white;
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  vertical-align: middle;
  transition: all $fast ease;
  &:hover {
    background-color: $aquamarine-hover;
    color: $white;
  }
}
.cross-link-blue {
  color: rgb(102, 201, 224);
  font-size: 0.875rem;
  line-height: 0.875rem;
  &:hover {
    text-decoration: underline;
  }
}
.cross-link {
  color: $text;
  &:hover {
    color: rgb(102, 201, 224);
    text-decoration: underline;
  }
}

.social-link {
  @include size(1.5rem);
  background: url('../img/icons/used/icon_vk-.png') center no-repeat;
}

.social-link-m {
  @include size(2rem);
  background: url('../img/icons/used/icon_vk-.png') center no-repeat;
  z-index: 104;
}