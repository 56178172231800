.main-container-sale {
  background-color: $whitesmoke;
  font-size: 0;
  min-width: $uncompressed;
  margin-top: 1rem;
  padding-bottom: 2rem;
}

.sales-container {
  @include outer-container();
  padding: 0 2rem;
  @include media($tablet) {
    padding: 0 0.5rem;
  }
  .sale-cell-1 {
    @include span-columns(6);
    @include media($pc) {
      @include span-columns(12);
      @include omega();
    }
    .text-h-sale {
      margin-bottom: 1rem;
    }
    .text-block-14 {
      line-height: 1.125rem;
    }
    margin-top: 1rem;
  }
  .sale-cell-2 {
    @include span-columns(6);
    @include omega();
    @include media($pc) {
      margin-top: 1rem;
      margin-bottom: 1rem;
      @include  span-columns(12);
      @include omega();
      @include media($tablet-s) {
        .img-operator {
          display: none;
        }
      }
    }
    margin-top: 1rem;
    padding: 1rem;
    background: $white;
    .block-h1 {
      line-height: 1.125rem;
      margin-bottom: 1rem;
    }
    .sale-form {
      padding-left: 0.5rem;
      padding-right: 13rem;
      background: url('../img/icons/img_operator.png') center right no-repeat;
    }
  }
  .sale-cell-3 {
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    background: $white;
    .s-cell-3-normalize {
      background: url('../img/icons/technical-sale.png') center left no-repeat;
      padding-left: 10rem;
    }

    .block-h1 {
      line-height: 1.125rem;
      border-bottom: 1px solid $gray-footer-bg;
      padding-bottom: 1rem;
    }
    .text-block-14 {
      padding-top: 1rem;
      height: 5rem;
      overflow: hidden;
      text-overflow: clip;
    }
    @include span-columns(6);
    @include omega(2n);
    @include media($header-unbreakable) {
      @include span-columns(12);
      @include omega();
      @include media($tablet-s) {
        padding: 1rem;
        .s-cell-3-normalize {
          background: none;
          padding-left: 0;
        }
      }
    }
  }
}