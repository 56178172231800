//
// Some Useful color Vars via http://clrs.cc/
//

// Cool
$aqua:  #7FDBFF;
//$blue:  #0074D9
$navy:  #001F3F;
$teal:  #39CCCC;
$green: #2ECC40;
$olive: #3D9970;
$lime:  #01FF70;


// Warm
$yellow:  #FFDC00;
$orange:  #FF851B;
$red:     #FF4136;
$fuchsia: #F012BE;
$purple:  #B10DC9;
$maroon:  #85144B;


// Gray Scale
$white:  #fff;
$silver: #ddd;
//$gray:   #aaa
$black:  #111;

// Work colors
$text: rgb(47, 47, 47);
$gray-copyright: rgb(106, 106, 106); //#6a6a6a
$gray-footer-bg: rgb(231, 231, 231); //#e7e7e7
$gray-header-bg: rgb(236, 239, 241); //#eceff1
$whitesmoke: rgb(245,245,245);
$whitesmoke-c: rgb(235,239,248);
$blue-hover: rgb(139, 191, 228); //primary #8bbfe4
$link-blue: rgb(102, 201, 224);
$blue: rgb(115, 170, 209); //primary #73aad1
$aquamarine: rgb(64, 212, 126); //primary
$aquamarine-hover: lighten( $aquamarine, 10% );

// Fonts

html {
  font-size: 16px; }

// Sizes

$logo: 50px;
//$uncompressed: 320px
$uncompressed: 0;

$bold: 700;
$normal: 400;
$lite: 300;


//Time
$fast: 0.33s;
$medium: 0.66s;
$slow: 1s;

$i: 1;

//$var-1: 3
//$var-2: 4n
