$tablet: new-breakpoint(max-width 768px 10);
$header-unbreakable: new-breakpoint(max-width 1024px 12);
$nth: 1;
@mixin omega-reset($nth, $context: null) {
  @if $context == null {
    &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  } @else {
    &:nth-child(#{$nth}) { margin-right: flex-gutter($context); }
  }
  &:nth-child(#{$nth}+1) { clear: none; }
}

body {
  position: relative;
  top: 2rem;
}
.outer-container {
  @include outer-container;
}
.span-3 {
  @include span-columns(3);
}
.span-4 {
  @include span-columns(4);
}
.span-6 {
  @include span-columns(6);
}
.span-8 {
  @include span-columns(8);
}
.span-12 {
  @include span-columns(12);
}
.omega {
  @include omega();
}
.blue-color{
  color: $blue;
}
.box-shadow {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
}
.box-shadow-header {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.5);
}
.main-triangle-container {
  background-color: $white;
  @include outer-container();
  .triangle {
    @include span-columns(12);
    @include omega();
    //border: 1px dashed blue;
    //height: 1rem;
    display: flex;
    justify-content: center;
    &-left {
      @include size(1rem);
      border: 1rem solid transparent;
      border-bottom-color: $white;
      border-left-width: 0;
      border-top-width: 0;
      border-right-color: $whitesmoke;
    }
    &-right {
      @include size(1rem);
      border: 1rem solid transparent;
      border-bottom-color: $white;
      border-right-width: 0;
      border-top-width: 0;
      border-left-color: $whitesmoke;
    }
  }
}
.white-triangle {
  background-color: $whitesmoke;
  .triangle-left {
    border-bottom-color: $whitesmoke;
    border-right-color: $white;
  }
  .triangle-right {
    border-bottom-color: $whitesmoke;
    border-left-color: $white;
  }
}

.input-class-check,
.input-class-name,
.input-class-phone {
  display: block;
  @include size(100% 3rem);
  font-size: 1rem;
  color: $text;
  border: 1px solid $blue-hover;
  padding-left: 4rem;
  margin-bottom: 1rem;
}
.input-class-check {
  background: url('../img/icons/icon_check_big.png') center left 1rem no-repeat;
}
.input-class-name {
  background: url('../img/icons/icon_man.png') center left 1.1875rem no-repeat;
}
.input-class-phone {
  background: url('../img/icons/icon_phone_bottom.png') center left 1rem no-repeat;
}

.img-contact-cell {
  padding: 1rem;
  .input-block {
    padding-left: 0.5rem;
  }
  .img-operator {
    height: 11rem;
    margin-left: 0.75rem;
  }
  .block-h1 {
    margin-bottom: 0.75rem;
  }
}
//Залочено до апдэйта хэдэра
.active_page{
  background-color: white;
  &:hover {
    background-color: $blue-hover;
  }
}
.pass-page {

}
.s-link {
  font-weight: $bold;
  color: $blue;
  &:hover {
    text-decoration: underline;
    color: $blue-hover;
  }
}

.text-justify,//замениить на .justify
.justify {
   display: flex;
   justify-content: space-between;
   align-items: center;
 }
.center {
  justify-content: center;
}
.start {
  justify-content: flex-start;
}
.end {
  justify-content: flex-end;
}
.column {
  flex-direction: column;
}
.space-between {
  justify-content: space-between;
}

.i-start {
  align-items: flex-start;
}
.i-end {
  align-items: flex-end;
}
.i-wrap {
  flex-wrap: nowrap;
}
.i-stretch {
  align-items: stretch;
}
.grow {
  flex-grow: 2;
}
.wrap {
  flex-wrap: wrap;
}


.full {
  @include size(100%);
}

.breadcrumbs {

  height: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-size: 0.75rem;
  line-height: 0.75rem;

  .cross-link-blue {
    font-size: 0.75rem;
    line-height: 0.75rem;
  }
}


.container {
  @include outer-container();
  padding: 0 2rem;
  font-size: 0;
  @include media($tablet) {
    padding: 0 0.5rem;
  }
  //min-width: $uncompressed;
}
.container-60 {
  @include outer-container(60);
  padding: 0 2rem;
  font-size: 0;
  @include media($tablet) {
    padding: 0 0.5rem;
  }
}

.whitesmoke-bg {
  background: $whitesmoke;
  min-width: $uncompressed;
}
.white-bg {
  background: $white;
  min-width: $uncompressed;
}
.warranty-image-bg {
  background: url('../img/images/warranty/warranty-background-image.png') center no-repeat;
  background-size: cover;
  min-width: $uncompressed;
}

.container-custom-service-breadcrumbs {
  margin-top: 0.5rem;
}
.phone-animation {
  animation-name: stretching;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 3s;
  animation-timing-function: linear;
  //color: $text;
}
@keyframes stretching {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

.margin-t1 {
  margin-top: 1rem;
}
.margin-t2 {
  margin-top: 2rem;
}

.margin-b2 {
  margin-bottom: 2rem;
}
.margin-b1 {
  margin-bottom: 1rem;
}
.margin-b05 {
  margin-bottom: 0.5rem;
}
.margin-l2 {
  margin-left: 2rem;
}
.padding-l2 {
  padding-left: 2rem;
}
.padding-l-05 {
  padding-left: 0.5rem;
}
.padding-b-05 {
  padding-bottom: 0.5rem;
}
.padding-b-8 {
  padding-bottom: 8rem;
}
.padding-t-05 {
  padding-top: 0.5rem;
}
.padding-t1 {
  padding-top: 1rem;
}

.padding-l-1 {
  padding-left: 1rem;
}
.margin-r-15 {
  margin-right: 1.5rem;
}

.whitesmoke-bg {
  background: $whitesmoke;
}
.white-bg {
  background: $white;
}

//костыль для IE
.ie-font-norm {
  font-weight: 300;
  font-size: 0.85rem;
}
//две колонки
.cc-2 {
  column-count: 2;
}
.border-bottom-whc {
  border-bottom: 1px solid $whitesmoke-c;
}

.f-left {
  float: left;
}
.span-6-tablet-5 {
  @include span-columns(6);
  @include omega(2n);
  @include media($tablet) {
    @include span-columns(10);
    //@include omega-reset(n);
    @include omega();
  }
}
.span-6-header-unbreakable-12 {
  @include span-columns(6);
  @include omega(2n);
  @include media($header-unbreakable) {
    @include span-columns(12);
    //@include omega-reset(n);
    @include omega();
  }
}
.span-4-header-unbreakable-12 {
  @include span-columns(4);
  @include omega(3n);
  @include media($header-unbreakable) {
    @include span-columns(12);
    //@include omega-reset(n);
    @include omega();
  }
}
.span-12 {
  @include span-columns(12);
}

//временная функция для просмотра разметки
.t {
  border: 1px dashed blue;
  height: 3rem;
}
.t2 {
  border: 1px dashed blue;
}
.t3 {
  border: 1px dashed red;
}
.t7 {
  position: relative;
}
.test-block {
  display: block;
}
.text-align-center-block {
  width: 100%;
  text-align: center;
}
