.price-menu {
  @include span-columns(6);
  @include omega();
  @include media($header-unbreakable) {
    @include span-columns(12);
    @include omega();
    .price-menu-cell-1 {
      @include span-columns(6 of 12);
      @include media($tablet-s) {
        display: none;
      }
    }
    .price-menu-cell-2 {
      @include span-columns(6 of 12);
      @include omega();
      @include media($tablet-s) {
        @include span-columns(4);
        @include omega();
      }
    }
    @include media($tablet-s) {
      padding-left: 0;
    }
  }
  background: $white;
  padding-left: 2rem;

  .price-menu-cell-1 {
    @include span-columns(3 of 6);
  }
  .price-menu-cell-2 {
    @include span-columns(3 of 6);
    @include omega();
    color: $blue;
    font-weight: $bold;
    .s-normalizer {
      justify-content: space-between;
    }
    position: relative;

    &:hover {
      .price-menu-list {
        display: block;
        z-index: 101;
      }
    }
  }
}

.price-block {
  @include span-columns(12);
  @include omega();
  @include media($tablet-s) {
    .padding-l2 {
      padding-left: 0.5rem;
    }
  }
  background: $white;
  padding-top: 1rem;
  margin-bottom: 2rem;

  .price-header {
    @include span-columns(12 of 12);
    @include omega();
    padding: 0 2rem;
    color: $blue;
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-weight: $bold;
    text-transform: uppercase;
  }

  .price-header-row {
    padding: 0 2rem;
    height: 3rem;
    color: $text;
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-weight: $bold;
    text-transform: uppercase;
  }
  .border-top {
    @include span-columns(12);
    border-top: 1px solid $whitesmoke;
  }
  .price-row {
    @include span-columns(12);
    //display: block;
    padding: 0 2rem;
    font-size: 1rem;
    line-height: 1rem;
    color: $text;
    font-weight: $normal;
    transition: all 0.3s ease;
    &:hover {
      background: $whitesmoke;
      color: $text;
    }
  }
}
.price-menu-list {
  @include span-columns(3 of 3);
  background: $white;
  display: none;
  position: absolute;
  top: 0;
}
.s-normalizer-menu {
  //@include span-columns(1 of 3);
  padding-left: 1rem;
  height: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $text;
  font-weight: $normal;
  transition: all 0.3s ease;

  &:hover {
    background: $whitesmoke;
    font-weight: $bold;
  }
}
.s-normalizer {
  //padding-left: 1rem;
  height: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

}
.img-price {
  max-height: 2rem;
}
.img-check {
  margin-right: 1rem;
}

.p-cell-1 {
  @include span-columns(6 of 12);
}
.p-cell-2,
.p-cell-3,
.p-cell-4 {
  @include span-columns(2 of 12);
}
.p-cell-4 {
  @include span-columns(2 of 12);
  @include omega();
}

//вынесено из price_new

.t-h {
  //padding: 0 2rem;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: $bold;
  text-transform: uppercase;
  text-align: left;
}
.blue {
  color: $blue;
}
.table-text {
  font-size: 1rem;
  line-height: 1rem;
  color: $text;
  font-weight: $normal;
}
.table-header{
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: $bold;
  text-transform: uppercase;
}
.p-table {
}
table {
  width: 100%;
  border-collapse: collapse;
}
tr {
  //padding-left: 1rem;
}
th {
  //padding-top: 1rem;
  padding-bottom: 1rem;
}
td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid $whitesmoke;
}
.row {
  //border-top: 1px solid $whitesmoke;
  &:hover {
    background: $whitesmoke;
    cursor: pointer;
  }
}
.img-sale-table {
  background: url('../img/images/c-price/icon_discount.png') center no-repeat;
}
.table-img-pad {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.col-1 {
  width: 50%;
}
.col-2,
.col-3,
.col-4 {
  width: 16.67%;
}
.col-first {
  padding-left: 2rem;
}
.color-transparent {
  //line-height: 2rem;
  display: inline-block;
  height: 2rem;
  color: transparent;
}

@media
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

  //коррекция
  //убираем строку Акции при разрешении <768px
  .table-img-pad,
  .col-2 {
    display: none;
  }

  /* Force table to not be like tables anymore */
  table, thead, tbody, th, td, tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  //tr { border: 1px solid #ccc; }

  td,
  .col-first {
    /* Behave  like a "row" */
    border: none;

    position: relative;
    padding-left: 30%;
    //padding-left: 50%;
    /*padding-top: 1rem;
    padding-bottom: 1rem;*/
    border-top: 1px solid $whitesmoke;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    //top: 6px;
    top: 0;
    //left: 6px;
    left: 2rem;
    @include media($tablet-s) {
      left: 0.5rem;
    }
    //width: 45%;
    width: 25%;

    padding-top: 1rem;
    //padding-bottom: 1rem;
    //padding-right: 10px;
    padding-right: 1rem;
    white-space: nowrap;
    //font-weight: $bold;
  }



  /*
  Label the data
  */
  /*Рабочая версия начало*/
  td:nth-of-type(1):before { content: "Услуги"; }
  td:nth-of-type(2):before { content: "Акция"; }
  td:nth-of-type(3):before { content: "Цены"; }
  td:nth-of-type(4):before { content: "Гарантии"; }
  /*Рабочая версия окончание*/
  /*td:nth-of-type(5):before { content: "Wars of Trek?"; }
  td:nth-of-type(6):before { content: "Porn Name"; }
  td:nth-of-type(7):before { content: "Date of Birth"; }
  td:nth-of-type(8):before { content: "Dream Vacation City"; }
  td:nth-of-type(9):before { content: "GPA"; }
  td:nth-of-type(10):before { content: "Arbitrary Data"; }*/
}