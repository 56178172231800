$laptop-h: new-breakpoint(max-width 1124px 12);

.main-container-sale {
  background-color: $whitesmoke;
  font-size: 0;
  min-width: $uncompressed;
  margin-top: 1rem;
  padding-bottom: 1rem;
}
.sales-triangle {
  background-color: $whitesmoke;
  .triangle-left {
    border-bottom-color: $whitesmoke;
    border-right-color: $white;
  }
  .triangle-right {
    border-bottom-color: $whitesmoke;
    border-left-color: $white;
  }
}

.warranty-container {
  @include outer-container();
  padding: 1rem 2rem 0 2rem;
  @include media($tablet) {
    padding: 1rem 0.5rem 0 0.5rem;
  }
  .warranty-h-cell {
    @include span-columns(12);
    @include omega();
    margin-bottom: 1rem;
  }
  .warranty-cell {
    @include span-columns(12);
    @include omega();
    margin-bottom: 1rem;
    //padding: 1rem 2rem;
    padding: 1rem;

    background: $white;

    .warranty-cell-normalize {
      background: url('../img/icons/technical-sale-2.png') center left no-repeat;
      padding-left: 23rem;
      //height: 9rem;
    }

    .justify {
      width: 100%;
    }
    /*.column {
      height: 7.125rem;
    }*/
    .block-h1 {
      line-height: 1rem;
      border-bottom: 1px solid $gray-footer-bg;
      padding-bottom: 0.875rem;
    }
    .text-block-16 {
      padding-top: 0.875rem;
      line-height: 1rem;
    }

  }

  $war-unbr: new-breakpoint(max-width 1040px 10);
  @include media($war-unbr) {
    .warranty-cell {
      .warranty-cell-normalize {
        .text-block-16 {
          font-size: 0.875rem;
          line-height: 0.875rem;
        }
      }
    }
  }
  $war-unbr2: new-breakpoint(max-width 870px 10);
  @include media($war-unbr2) {
    .warranty-cell {
      .warranty-cell-normalize {
        height: auto;
        background: none;
        padding: 0;
        .text-block-16 {
          font-size: 1rem;
          line-height: 1.125rem;
          padding-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }

  }


  @include media($tablet) {
    .warranty-container {
      padding: 0 0.5rem;
    }
  }
}
//ниже код для новой страницы гарантий
.shift-4-span-8 {
  //@include shift(4);
  @include span-columns(8);
}
.warranty-header-image-left {
  float: left;
  @include span-columns(4);
}
.warranty-text-block {
  color: $text;
  font-size: 1rem;
  line-height: 1rem;
  position: relative;
  &-blue-border {
    background-color: white;
    border: 0.313rem solid rgb(115, 177, 214);
    padding: 3rem;
    //margin-bottom: 8rem;
    position: relative;
    &-2 {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    h4 {
      margin-top: 0;
    }
    p {
      margin-bottom: 0;
      position: relative;
    }
  }
  &-dark-shadow {
    padding: 2rem;
    color: $white;
    background-color: rgba(47, 47, 47, 0.9);
    box-shadow: 0 0.625rem 1.875rem rgba(0, 0, 2, 0.15);
  }
}

.span-5-7 {
  @include span-columns(5 of 7);
}
.shift-3-span-6 {
  @include span-columns(6);
  @include shift(3);
}
.shift-2-span-5-7 {
  @include span-columns(5 of 7);
  @include shift(2 of 7);
}
.shift-1-span-6-7 {
  @include span-columns(6 of 7);
  @include shift(1 of 7);
}
.span-7 {
  @include span-columns(7);
}
.shift-5-span-7 {
  @include span-columns(7);
  @include shift(5);
}
.p-relative {
  position: relative;
}
.container-warranty {
  &-1 {
    padding-top: 2rem;
    padding-bottom: 8rem;
  }
  &-3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  &-4 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  &-img {
    position: absolute;
    height: 100%;
    &-1 {
      top: 6rem;
      right: 0;
      background: url('../img/images/warranty/warranty-working-image.png') center no-repeat;
      background-size: cover;
    }
    &-2 {
      left: 0;
      top: -2rem;
      //min-height: 100%;
      height: 26rem;
      //@include size(38.063rem 26.563rem);
      background: url('../img/images/warranty/warranty-example-image.png') left center no-repeat;
      background-size: contain;
    }
  }
}
.whitesmoke-bg-lg {
  background: $whitesmoke; /* Цвет фона */
  background: linear-gradient(to right, $blue-hover 50%, $whitesmoke 50%);
}
@include media($laptop-h) {
  .container-warranty-img-2 {
    height: 24rem;
  }
  @include media($laptop) {
    .container-warranty-img-2 {
      display: none;
    }
    .warranty-text-block-blue-border-2 {
      @include span-columns(12);
      @include shift(0);
      @include omega();
      .warranty-text-block-3 {
        @include span-columns(12);
        @include shift(0);
      }
    }
    .warranty-text-block-dark-shadow {
      @include span-columns(10);
      @include shift(1);
    }
    @include media($tablet) {
      .warranty-text-block-blue-border {
        padding: 1rem;
      }
      .warranty-text-block-blue-border-2 {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      .warranty-header-image-left {
        @include span-columns(4);
      }
      .warranty-text-block-blue-border-1 {
        @include span-columns(10);
        @include omega();
        margin-bottom: 1rem;
      }
      .warranty-text-block-2 {

        @include span-columns(10);
        @include shift(0);
        @include omega();
      }
      .container-warranty-1 {
       // padding-bottom: 1rem;
      }
      .container-warranty-img-1 {
        position: relative;
        top: 0;
        //margin-bottom: 1rem;
        //height: 15rem;
        @include span-columns(10);
        @include shift(0);
        @include omega();
      }
      .whitesmoke-bg-lg {
        background: $whitesmoke;
        .warranty-text-block-5 {
          background: $blue-hover;
          margin-bottom: 1rem;
        }
      }
      .warranty-text-block-dark-shadow {
        padding: 1rem 2rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}