// This is the single file output by sass. It is intended to ONLY @import other files.


// 1 - TOOLS
@import '1-tools/bourbon/bourbon';
@import "1-tools/neat-settings";
@import "1-tools/vars";
@import '1-tools/neat/neat';
@import '1-tools/neat/neat-helpers';
@import '1-tools/fonts';
@import '1-tools/normalize';



// 2 - BASICS
@import '2-basics/helpers';
@import '2-basics/selection-colors';
@import '2-basics/links';
@import "2-basics/typography";
@import "2-basics/buttons";
@import "2-basics/carousel-elements";
@import '2-basics/grid';

// 3 - Modules
@import '3-modules/example-module-name-lockup';
@import '3-modules/example-fork-tag';
@import '3-modules/example-flex-video';
@import "3-modules/service-blue-block";

// 4 - pages
@import "4-pages/body-element";
@import "4-pages/header";
@import "4-pages/footer";
@import "4-pages/example-page-home";
@import "4-pages/sales";
@import "4-pages/warranty";
@import "4-pages/contacts";
@import "4-pages/custom-service";
@import "4-pages/about";
@import "4-pages/custom-press";
@import "4-pages/custom-work";
@import "4-pages/service";
@import "4-pages/price";
@import "4-pages/comments";

