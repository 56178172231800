$form-fixer: new-breakpoint(max-width 1212px 12);
.c-work-block-2 {
  margin-bottom: 1rem;
  .block-h {
    display: block;
    margin-bottom: 1.125rem;
    @include shift(3);
    @include media($header-unbreakable) {
      @include shift(4);
      @include media($tablet) {
        @include shift(0);
      }
    }
  }
  .block-w-image {
    display: block;
    height: 12rem;
    //float: left;
    background: url('../img/images/c-work/img_noutbook.png') center no-repeat;
    background-size: contain;
    @include span-columns(3);
    @include media($header-unbreakable) {
      @include span-columns(4);
      @include media($tablet) {
        @include span-columns(10);
        @include omega();
      }
    }
  }
  //обновление 30-06-16 изображение перенесено в img html
  .c-work-block-2-h-image {
    text-align: center;
    //@include size(17rem, 12rem);
    &-size {
      @include size(100%);
      //width: 100%;
      //height: 100%;
    }
    @include span-columns(3);
    @include media($header-unbreakable) {
      @include span-columns(4);
      @include media($tablet) {
        @include span-columns(10);
        @include omega();
        .c-work-block-2-h-image-size {
          @include size(auto);
        }
      }
    }

  }
  @include span-columns(12);
  @include omega();
}
.apple-cell {
  //@include span-columns(3);
  display: block;
  //margin-bottom: 1rem;
  vertical-align: middle;
  height: 17rem;
  background: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.75);
  }
  div {
    height: 100%;
  }
  p {
    font-size: 1rem;
    font-weight: 700;
    color: $text;
    padding: 0;
    margin: 2rem 0 0 0;
  }
}
.apple-cell-main {
  margin-bottom: 1rem;
  @include span-columns(3);
  @include omega(4n);
  @include media($header-unbreakable) {
    @include span-columns(4);
    @include omega-reset(1n);
    @include omega(3n);
    @include media($tablet) {
      @include span-columns(5);
      @include omega-reset(1n);
      @include omega(2n);
      @include media($h-phone-unbreakable) {
        @include span-columns(4);
        @include omega();
      }
    }
  }
}
//.apple-cell-custom,
.apple-cell-custom-4,
.apple-cell-custom-5 {
  height: 12rem;
  margin-top: 1rem;
  p {
    margin-top: 0.5rem;
  }
  &:last-child {
    margin-bottom: 2rem;
  }
}
.apple-cell-custom-4 {
  @include span-columns(5 of 20);
  @include omega(4n);
  @include media($tablet) {
    @include span-columns(5 of 10);
    @include omega-reset(1n);
    @include omega(2n);
  }
}
.apple-cell-custom-5 {
  @include span-columns(4 of 20);
  @include omega(5n);
  @include media($tablet) {
    @include span-columns(5 of 10);
    @include omega-reset(1n);
    @include omega(2n);
  }
}

.c-work-block-3 {
  margin-top: 1rem;
  .block-h3 {
    margin-bottom: 1rem;
  }
  .c-work-box-type-1 {
    background: $white;
    padding: 0.5rem 0;
    //margin-bottom: 2rem;
    &-cell {
      display: block;
      padding: 0 1.5rem;
      transition: all 0.3s ease;
      color: $text;
      &-center {
        padding-left: 2.5rem;
        height: 3rem;
        display: flex;
        align-items: center;
        font-size: 1rem;
        line-height: 1rem;
        color: $text;
        font-weight: $normal;
        border-bottom: 1px solid $whitesmoke;
      }
      $i: 1;
      @for $i from 1 through 11 {
        .c-work-box-type-1-cell-center-#{$i} {
          background: url('../img/images/c-work/box-type-1/#{$i}.png') left center no-repeat;
        }
      }
    }
    .c-work-box-type-1-cell:hover {
      background: $whitesmoke;
      color: $text;
    }
  }
  .invisible-space {
    display: none;
  }
  @include span-columns(3);
  @include media($pc) {
    @include span-columns(4);
    @include media($header-unbreakable) {
      @include span-columns(6);
      @include media($tablet) {
        @include span-columns(10);
        @include omega();
      }
    }
  }
}

.c-work-block-4 {
  margin-top: 1rem;
  min-height: 36.0625rem;
  .block-h3 {
    margin-bottom: 1rem;
  }
  .c-work-box-type-2 {
    //margin-bottom: 2rem;
    //border: 1px dashed red;
    &-img {
      min-height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      //margin-bottom: 0.5rem;
      //margin-right: 1rem;
      margin: 0 1rem 0.5rem 0;
      min-width: 4rem;
      border-right: 1px solid $whitesmoke;
    }
    .cross-link {
      font-size: 1rem;
      line-height: 1rem;
      color: $text;
      font-weight: $normal;
    }
    &-cell {
      @include span-columns(4 of 12);
      @include omega(3n);
      background: $white;
      display: block;
      margin-bottom: 1rem;
      //margin-top: 1rem;
      padding: 0.75rem 1rem;
      transition: all 0.3s ease;
      &-center {
        padding-left: 4rem;
        margin-bottom: 0.5rem;
        //height: 3rem;

        .border-left {
          border-left: 1px solid $whitesmoke;
          height: 3rem;
          padding-left: 1rem;
          display: flex;
          align-items: center;
          font-size: 1rem;
          line-height: 1rem;
          color: $text;
          font-weight: $normal;
        }
      }
      &:hover {
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.75);
        cursor: pointer;
        .text-block-16 {
          text-decoration: underline;
          color: $text;
        }
      }
      $i: 1;
      @for $i from 1 through 14 {
        .c-work-box-type-2-cell-center-#{$i} {
          background: url('../img/images/c-work/box-type-2/#{$i}.png') left center no-repeat;
          @if $i == 10 {
            background: url('../img/images/c-work/box-type-2/#{$i}.png') left 0.5rem center no-repeat;
          }
          @else if $i == 14 {
            background: url('../img/images/c-work/box-type-2/#{$i}.png') left 0.5rem center no-repeat;
          }
        }

      }
      @include media($pc) {
        @include span-columns(4 of 8);
        @include omega-reset(1n);
        @include omega(2n);
        @include media($header-unbreakable) {
          @include span-columns(12 of 12);
          @include omega();
        }
      }
    }
  }
  @include span-columns(9);
  @include omega();
  @include media($pc) {
    @include span-columns(8);
    @include omega();
    @include media($header-unbreakable) {
      @include span-columns(6);
      @include omega();
      @include media($tablet) {
        @include span-columns(10);
        @include omega();
      }
    }
  }
}
.c-work-block-4-apple {
  .c-work-box-type-2-cell {
    padding: 0;

  }
  margin-bottom: 1rem;
}
.c-work-text-block {
  color: $text;
  font-size: 0.875rem;
  line-height: 1.125rem;
  //margin-bottom: 1rem;
}

.c-work-text-block-21 {
  @include span-columns(6);
  @include media($tablet) {
    @include span-columns(10);
    @include omega();
  }
}
.c-work-text-block-22 {
  margin-bottom: 1rem;
  @include span-columns(6);
  @include omega();
  @include media($tablet) {
    @include span-columns(10);
    @include omega();
  }
}

.c-work-text-block-31 {
  @include span-columns(4 of 12);
  @include media($tablet) {
    @include span-columns(10);
    @include omega();
  }
}
.c-work-text-block-32 {
  @include span-columns(4 of 12);
  @include media($tablet) {
    @include span-columns(10);
    @include omega();
  }
}
.c-work-text-block-33 {
  margin-bottom: 1rem;
  @include span-columns(4 of 12);
  @include omega();
  @include media($tablet) {
    @include span-columns(10);
    @include omega();
  }
}
.test_abc {
  position: relative;
}
.test_hand {
  position: absolute;
  left: 0;
  top: 0;
}
.hand-with-ipad {
  @include outer-container();
  min-height: 18rem;
  @include size(min-content auto);
  &-img {
    @include span-columns(12);
    min-height: 25rem;
    background: url('../img/images/c-work/work_apple_hand.png') left center no-repeat;
    background-size: contain;
    position: absolute;
    left: 0;
    top: -3rem;
  }
  @include media($tablet) {
    min-height: 12rem;
    &-img {
      top: -6rem;
    }
  }
}

.c-work-block-5 {
  @include span-columns(6);
  margin-top: 1rem;
  &-normalizer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-height: 17.875rem;
    .block-h1 {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-bottom: 0.75rem;
    }
    .img-block-5 {
      @include size(100% 10rem);
      background: url('../img/images/c-work/img-block-6.png') left center no-repeat;
      background-size: cover;
      margin-bottom: 1rem;
      .justify {
        padding: 0 1rem;
        height: 10rem;
      }
    }
    .text-block-14 {
      padding-left: 1rem;
      padding-right: 1rem;
      max-height: 3rem;
    }
  }
  .start {
    height: 2rem;
  }
}

.c-work-block-6 {
  @include span-columns(6);
  @include omega();
  @include media($tablet-s) {
    .img-operator {
      display: none;
    }
  }
  margin-top: 1rem;
  margin-bottom: 2rem;
}

@include media($header-unbreakable) {
  .c-work-block-5,
  .c-work-block-6 {
    @include span-columns(12 of 12);
    @include omega();
  }
}
.iphone-decoration-img-bottom {
  @include span-columns(6);
  @include shift(3);
  @include media($tablet) {
    @include span-columns(6);
    @include shift(2);
    @include media($mobile) {
      @include span-columns(4);
      @include shift(0);
    }
  }
}
.ipad-decoration-img-bottom {
  @include span-columns(8);
  @include shift(2);
  @include media($tablet) {
    @include span-columns(8);
    @include shift(1);
    @include media($mobile) {
      @include span-columns(4);
      @include shift(0);
    }
  }
}
@include media($form-fixer) {
  .c-work-block-5-normalizer {
    //min-height: 17.875rem;
    .block-h1 {
      width: 75%;
      //margin-bottom: 1.125rem;
    }
  }
}