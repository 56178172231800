.img-user {
  max-height: 4rem;
}

.container-custom-service-1 {
  margin-top: 1rem;
  .cell-custom-service-2 {
    padding-bottom: 2rem;
    margin-top: 2rem;
    .block-h {
      margin-bottom: 1rem;
      @include shift(3 of 12);
      @include media($header-unbreakable) {
        @include shift(4);
        @include media($tablet) {
          @include shift(0);
        }
      }
    }
    .block-s-image {
      height: 11rem;
      float: left;
      background: url('../img/icons/custom-service-01.png') center no-repeat;
      background-size: contain;
      @include span-columns(3);
      @include media($header-unbreakable) {
        @include span-columns(4);
        @include media($tablet) {
          @include span-columns(10);
          @include omega();
        }
      }
    }
    @include span-columns(12);
    @include omega();
  }
}


.container-custom-service-2 {
  .cell-custom-service-3 {
    margin-bottom: 2rem;
    padding: 1rem 1.5rem;
    .service-table {
      .border-bottom-sc {
        @include size(100%);
        padding-bottom: 1rem;
        padding-top: 1rem;
        border-bottom: 1px solid $gray-footer-bg;
        &:first-child {
          padding-top: 0;
          padding-bottom: 0.875rem;
        }
        .b-line {
          display: inline;
        }
        .b-block {
          display: none;
        }
        @include media($mobile) {
          .b-line {
            display: none;
          }
          .b-block {
            display: inline;
          }
        }
      }
    }
    .service-info {
      .text-block-16 {
        //max-height: 2.25rem;
        max-height: 2.3125rem; //корректировка высоты со вторым блоком
        line-height: 1.125rem;
      }
    }
    @include span-columns(6);
    @include media($pc) {
      @include span-columns(12 of 12);
      @include omega();
      @include media($mobile) {
        padding: 1rem;
        .block-h1 {
          font-size: 1rem;
        }
        .text-block-16 {
          font-size: 0.875rem;
        }
        .padding-l2 {
          padding-left: 1rem;
        }
      }
    }
  }
  .cell-custom-service-4 {
    @include span-columns(6);
    @include omega();
    margin-bottom: 2rem;
    @include media($pc) {
      @include span-columns(12 of 12);
      @include omega();
      @include media($tablet-s) {
        .img-operator {
          display: none;
        }
      }
    }
  }
  .cell-custom-service-5 {
    @include span-columns(12);
    @include omega();
    margin-bottom: 2rem;
  }

}

@include media($tablet) {
  .bc-container {
    padding: 0 0.5rem;
  }
}

