.footer-all {
  background-color: $gray-footer-bg;
  color: $text;
  font-size: 0;
  min-width: $uncompressed;

  .footer-contacts {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    padding-left: 2.75rem;
    background: url('../img/icons/used/icon_map_bottom.png') left 5px center no-repeat;
  }

  .footer-outer-container {
    min-width: $uncompressed;
    @include outer-container();
    padding: 0 2rem;

    @include media($tablet) {
      padding: 0 0.5rem;
    }

    .footer-top-cell-1,
    .footer-top-cell-2,
    .footer-top-cell-3,
    .footer-top-cell-4 {
      height: $logo;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1rem;
      line-height: 1.125rem;
      .justify {
        height: $logo;
      }
    }

    .footer-top-cell-1 {
      background: url('../img/logo/logo.png') left no-repeat;
      @include span-columns(6 of 24);
      @include media($pc){
        @include span-columns(12);
        @include omega();
        background-position: center;
        @include media($mc2-unbr) {
          @include span-columns(10);
          @include omega();
          background-position: center;
        }
      }
    }
    .footer-top-cell-2 {
      @include span-columns(6 of 24);
      @include media($pc){
        @include omega-reset(1n);
        @include span-columns(4);
        .i-end {
          align-items: flex-start;
        }
        @include media($mc2-unbr) {
          @include span-columns(10);
          @include omega();
          .i-end {
            align-items: center;
          }
        }
      }
    }
    .footer-top-cell-3 {
      .footer-contacts {
        background: url('../img/icons/used/icon_time_bottom.png') left center no-repeat;
      }
      @include span-columns(6 of 24);
      @include media($pc) {
        @include span-columns(4);
        .i-end {
          align-items: center;
        }
        @include media($mc2-unbr) {
          @include span-columns(10);
          @include omega();
          .i-end {
            align-items: center;
          }
        }
      }
    }
    .footer-top-cell-4 {
      .footer-contacts {
        background: url('../img/icons/used/icon_phone_bottom.png') left center no-repeat;
        text-align: right;
        &-phone {
          color: $text; //edge
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
        &-info {
          color: $aquamarine;
          font-size: 1rem;
          line-height: 1rem;
        }
      }
      @include span-columns(6 of 24);
      @include omega();
      @include media($pc) {
        @include span-columns(4);
        @include omega();
        .i-end {
          align-items: flex-end;
        }
        @include media($mc2-unbr) {
          @include span-columns(10);
          @include omega();
          .i-end {
            align-items: center;
          }
        }
      }
    }
    .footer-middle-line {
      height: 1px;
      background-color: $white;
    }
    .footer-bottom-cell {
      min-height: 2rem;
      font-size: 0.75rem;
      font-weight: $lite;
      //line-height: 2rem;
      color: $gray-copyright; //color: #6a6a6a;
    }
  }
}
