.flex-video {
  height: 0;
  margin-bottom: 0.88889rem;
  overflow: hidden;
  padding-bottom: 56%;
  position: relative; }

.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0; }
