//Рабочая версия
/*.cell-about-1 {
  @include span-columns(8 of 12);
  @include media($header-unbreakable){
    @include span-columns(12 of 12);
    @include omega();
  }
}
.cell-about-2 {
 @include span-columns(4 of 12);
 @include omega();
 @include media($header-unbreakable){
   @include span-columns(12 of 12);
   @include omega();
 }
 background: url('../img/images/img-about-vechicle.png') center no-repeat;
 background-size: contain;
 height: 17rem;
//.img-about-vechicle {
    //max-height: 17rem;
  //}
}*/
.cell-about-1 {
  .img-about-vechicle {
    float: right;
    margin-left: 1rem;
  }
  .brand-text-c1 {
    @include span-columns(6 of 12);
    @include media($tablet) {
      @include span-columns(4 of 4);
      @include omega();
    }
  }
  .brand-text-c2 {
    @include span-columns(6 of 12);
    @include omega();
  }
  .brand-text-c3 {
    @include span-columns(12 of 12);
    @include omega();
  }

  @include span-columns(12);
  @include omega();
  @include media($tablet-s) {
    .img-about-vechicle {
      display: none;
    }
  }

}


.cell-about-3 {
  @include span-columns(12);
  @include omega();
  .img-facility {
    max-height: 4rem;
  }
  .text-block-16 {
    line-height: 1rem;
    text-align: center;
  }
  &-facility {
    flex-grow: 2;
    display: inline-block;
    padding: 1rem 0;
    @include size(14rem 8rem);
    transition: all 0.3s ease;
    //border: 1px dashed red;
    &-text {
      display: none;
    }
  }
  &-facility:hover {
    background: $whitesmoke;
    .img-facility,
    .text-block-16 {
     display: none;
    }
    .cell-about-3-facility-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0 1rem;
      color: $text;
      font-size: 0.875rem;
      line-height: 1rem;
      @include size(100%);
      //height: 6rem;
      &-sp {
        display: block;
        //@include size(100%);
        width: 100%;
        overflow: hidden;
        text-overflow: clip;
      }
    }
  }
}
.cell-about-4,
.cell-about-5 {
  @include span-columns(6 of 12);
  @include media($header-unbreakable){
    @include span-columns(12 of 12);
    @include omega();
    .img-about-process-1 {
      //@include size(100%);
      padding-left: 2rem;
      padding-right: 2rem;
      max-height: 10rem;
      max-width: 35.625rem;
      //background: url('../img/images/about/img-about-process-1.png') center no-repeat;
    }
  }
  background: $white;
  padding: 1rem 0;
  //margin-bottom: 2rem;
  .block-h1 {
    padding: 0 2rem;
    margin-bottom: 1rem;
  }
  .img-about-process-1 {
    @include size(100%);
    max-height: 10rem;
    //background: url('../img/images/about/img-about-process-1.png') center no-repeat;
  }
  .text-block-14 {
    padding: 0 2rem;
    margin-top: 1rem;
    max-height: 3rem;//временное свойство
  }
}
.cell-about-5 {
  @include omega();
}
.cell-5a {
  @include span-columns(12);
  @include omega();
  .end {
    height: 2rem;
  }
}
.cell-about-6-1,
.cell-about-6-2,
.cell-about-6-3 {
  @include span-columns(4);
  @include media($header-unbreakable){
    @include span-columns(12 of 12);
    @include omega();
    @include media($tablet) {
      padding: 1rem;
    }
  }
  margin-top: 2rem;
  //margin-bottom: 2rem;
  padding: 1rem 2rem;
  .img-about-avatar {
    max-height: 4rem;
    margin-right: 1rem;
  }
  .img-about-vk {
    max-height: 0.875rem;
    margin-right: 1rem;
  }
  .column {
    height: 4rem;
  }
  .text-block-14 {
    height: 5rem;
  }
}
.cell-about-6-3 {
  @include omega();
}

.cell-about-6a {
  @include span-columns(12);
  @include omega();
  .center {
    height: 2rem;
  }
}