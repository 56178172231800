@charset "UTF-8";

/// Changes the display property used by other mixins called in the code block argument.
///
/// @param {String} $display [block]
///   Display value to be used within the block. Can be `table` or `block`.
///
/// @example scss
///   @include display-context(table) {
///    .display-table {
///      @include span-columns(6);
///     }
///   }
///
/// @example css
///   .display-table {
///      display: table-cell;
///      ...
///   }

@mixin display-context($display: block) {
  $scope-display: $container-display-table;
  $container-display-table: $display == table !global;

  @content;

  $container-display-table: $scope-display !global;
}
