.carousel-arrow {
  display: inline-block;
  @include size(3rem);
  min-width: 3rem;
  background: url('../img/icons/icon_arrows.png') center no-repeat;
  //background-size: contain;
  &:last-child {
    background: url('../img/icons/icon_arrows-right.png') center no-repeat;
  }
  &:hover{
    opacity: 0.5;
    background-color: $blue;
  }
}
.carousel-block-2 {
  padding: 1rem 2rem;
  .block-h1 {
    line-height: 2rem;
    margin-bottom: 0.5rem;
  }
}
.carousel-arrow-mini {
  display: inline-block;
  width: 2rem;
  height: 3rem;
  background: url('../img/icons/icon_arrows-mini.png') center no-repeat;
  &:last-child {
    transform: rotate(180deg);
  }
  &:hover{
    background-color: $whitesmoke;
  }
}
.carousel-brand {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .cross-link-blue {
    @include span-columns(4);
    font-size: 1.125rem;
    height: 5rem;
    padding-top: 3.875rem;
    vertical-align: bottom;
    text-align: center;
    &:last-child {
      margin-right: 0;
    }
  }
  $i: 1;
  @for $i from 1 through 6 {
    .element-#{$i} {
      background: url('../img/icons/brand/0#{$i}.png') center top no-repeat;
    }
  }
}
.trial-carousel {
  display: block;
  width: 3rem;
  height: 1rem;
  margin-right: 0.75rem;
  background-color: #ebeff8;
  transition: all $fast ease;
  &:first-child {
    background-color: $blue;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover{
    background-color: $blue;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  }
}

.main-carousel-bar {
  @include span-columns(12);
  @include omega();

  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .trial-carousel {
    display: block;
    width: 3rem;
    height: 1rem;
    margin-right: 13px;
    background-color: #ebeff8;
    transition: all $fast ease;
    &:first-child {
      background-color: $blue;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover{
      background-color: $blue;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    }
  }
}