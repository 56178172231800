.fork-tag {
  position: absolute;
  top: 10px;
  right: -10px;
  background: $blue;
  color: white;
  padding: 2px 6px;
  transition: all 0.3s ease-in-out;
  transform-origin: top right;

  &:hover {
    color: white;
    animation: wiggle 0.4s linear 1; } }

@keyframes wiggle {
  0% {
    transform: rotate(0deg); }
  20% {
    transform: rotate(8deg); }
  50% {
    transform: rotate(-8deg); }
  80% {
    transform: rotate(8deg); }
  100% {
    transform: rotate(0deg); } }
