::selection {
  background: #FFF498; }

::-moz-selection {
  background: #FFF498; }

img::selection {
  background: transparent; }

img::-moz-selection {
  background: transparent; }

body {
  -webkit-tap-highlight-color: #FFF498; }
