.text-img {
  @include size(100% 4rem);
  background: url('../img/images/2.jpg') 100% 100% no-repeat;
  background-position: center;
  background-size: cover;
}
.block-h,
.text-h-sale {
  font-size: 1.75rem;
  font-weight: $bold;
  line-height: 1.5rem;
  text-transform: uppercase;
}
.text-h1,
.block-h1{
  color: $text;
  font-size: 1.125rem;
  line-height: 1.125rem; //добавлено к service-custom.html, проверить корректность
  font-weight: $bold;
  text-transform: uppercase;
  vertical-align: middle;
}
.text-h2,
.block-h2{
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: $bold;
}
.block-h3 {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: $bold;
  text-transform: uppercase;
}
h4 {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: $bold;
}
.text-block-16 {
  font-size: 1rem;
  //line-height: 1rem;
  color: $text;
  font-weight: $normal;
}
.block-14 {
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.text-s,
.text-block-14{
  font-size: 0.875rem;
  line-height: 1rem;
  overflow: hidden;
  text-overflow: clip;
}
.text-xs,
.text-block-12{
  font-size: 0.75rem;
  //line-height: 1.125em;
  line-height: 1rem;
}
.text-date {
  font-weight: $lite;
  font-size: 0.875rem;
  line-height: 1rem;
}

.overflow {
  overflow: hidden;
  text-overflow: clip;
}
.white-space {
  white-space: nowrap;
}
.aquamarine {
  color: $aquamarine;
  &:hover {
    color: $aquamarine;
    text-decoration: underline;
  }
}

//upd 14-06-16
.h1,
.h2,
.h3,
.h4 {
  color: $text;
  font-weight: $bold;
  text-transform: uppercase;
}

.h1 {
  font-size: 1.75rem;
}
.h2 {
  font-size: 1.125rem;
}
.h3 {
  font-size: 1rem;
}
.h4 {
  font-size: 0.875rem;
}
.lh1 {
  line-height: 1rem;
}
