$pc: new-breakpoint(max-width 1280px 12);

$header-unbreakable: new-breakpoint(max-width 1024px 12);
$laptop: new-breakpoint(max-width 1024px 12);
$mc2-unbr: new-breakpoint(max-width 832px 10);
$tablet: new-breakpoint(max-width 768px 10);
$tablet-s: new-breakpoint(max-width 568px 4);
$footer-unbr: new-breakpoint(max-width 600px 4);
$h-phone-unbreakable: new-breakpoint(max-width 512px 4);
$mobile: new-breakpoint(max-width 480px 4);

$nth: 1;
@mixin omega-reset($nth, $context: null) {
  @if $context == null {
    &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  } @else {
    &:nth-child(#{$nth}) { margin-right: flex-gutter($context); }
  }
  &:nth-child(#{$nth}+1) { clear: none; }
}


.main-container-1 {
  color: $text;
  font-size: 0;
  min-width: $uncompressed;
  //padding: 1rem 2rem 0 2rem;
  padding: 0 2rem;
  //margin-top: 2rem;

  @include outer-container();

  /*@include media($header-unbreakable) {
    .main-container-1-cell {
      @include span-columns(4);
      @include omega-reset(4n);
      @include omega(3n);
    }
  }*/
  @include media($tablet) {
    padding: 0 0.5rem;
    /*.main-container-1-cell {
      @include span-columns(5);
      @include omega-reset(3n);
      @include omega(2n);
    }*/
    .main-carousel-promo {
      width: 10rem;
    }
  }
  //Устанавливает кнопки сервиса по одной на строку Начало
  /*@include media($mobile) {
    padding: 1rem 2rem 0 2rem;
    .main-container-1-cell {
      @include span-columns(4);
      @include omega-reset(2n);
      @include omega(n);
    }
  }*/
  //Окончание

  .main-container-1-cell {
    @include span-columns(3);
    @include omega(4n);
    @include media($header-unbreakable) {
      @include span-columns(4);
      @include omega-reset(1n);
      @include omega(3n);
      @include media($tablet) {
        @include span-columns(5);
        @include omega-reset(1n);
        @include omega(2n);
        @include media($mobile) {
          @include span-columns(4);
          @include omega();
        }
      }
    }

    height: 4rem;
    margin-bottom: 1rem;
    background-color: $blue;
    color: $white;
    font-size: 1rem;
    line-height: 1rem;
    transition: all $fast ease;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $blue-hover;
    }

    .main-top-text {
      //padding-left: 1rem;
      padding: 0 1rem;
    }

    .justify-mc-1 {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      display: flex;
      flex-direction: row;
      align-items: center;
    }

    @for $i from 1 through 8 {
      .main-icon-#{$i} {
        height: 3rem;
        min-width: 5rem;
        border-right: 1px solid white;
        background: url('../img/icons/mc-1/0#{$i}.png') center no-repeat;
      }
    }
  }
  //Классы для имитации карусели начало
  .main-carousel {
    background-image: url('../img/images/1.jpg');
    background-size: cover;
    padding: 2rem 1.125rem;
    @include span-columns(12);
    @include omega();
    @include media($tablet) {
      padding: 1rem 0;
    }
    .main-carousel-promo {
      background-color: rgba(255, 255, 255, 0.9);
      border: 1px solid white;
      padding: 2rem;
      margin-left: 2rem;
      @include span-columns(6);
      @include media($tablet) {
        @include span-columns(10);
        margin-left: 0;
        padding: 1.5rem;
        @include media($mobile) {
          @include span-columns(4);
          padding: 1rem;
          margin-left: 0;
          .button-about {
            width: 8rem;
          }
        }
      }
      .main-carousel-promo-1 {
        font-size: 1.5rem;
        font-weight: $bold;
        line-height: 1.25rem;
        margin-bottom: 1.625rem;
        @include media($mobile) {
          font-size: 1.25rem;
        }
      }
      .main-carousel-promo-2 {
        height: 3.375rem;
        text-overflow: clip;
        overflow: hidden;
        font-size: 1rem;
        font-weight: $normal;
        line-height: 1.125rem;
        margin-bottom: 1.625rem;
      }
    }
  }
  .main-carousel-bar {
    @include span-columns(12);
    @include omega();

    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .trial-carousel {
      display: block;
      width: 3rem;
      height: 1rem;
      margin-right: 13px;
      background-color: #ebeff8;
      transition: all $fast ease;
      &:first-child {
        background-color: $blue;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover{
        background-color: $blue;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
      }
    }
  }
  //Классы для имитации карусели окончание
}
.main-gray-container {
  background-color: $whitesmoke;
  font-size: 0;
  min-width: $uncompressed;
  //padding: 2rem;

  /*  @include media($tablet) {
    padding: 1rem 0.5rem 0 0.5rem;}
  */

  .main-container-2 {
    @include outer-container();
    padding: 0 2rem;
    @include media($tablet) {
      padding: 0 0.5rem;
    }
    .mc2-block-1 {
      background-color: $white;

      .block-h1,
      &-promo,
      &-btn {
        width: 100%;
        padding: 0 2rem;
        display: block;
      }
      .block-h1 {
        //min-height: 3rem;
        //display: flex;
        //justify-content: space-between;
        //align-items: center;
        //line-height: 3rem;
        &-border {
          border-bottom: 1px solid $whitesmoke;
          height: 3rem;
          width: 100%;
          display: flex;
          align-items: center;
        }
      }
      &-promo {
        transition: all $fast ease;
        font-size: 1rem;
        //box-sizing: border-box;
        //line-height: 3rem;
        color: $text;

        &-border {
          box-sizing: border-box;
          height: 3rem;
          border-bottom: 1px solid $whitesmoke;
          transition: all $fast ease;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &-ub {
            min-width: 5rem;
            text-align: right;
          }
          /*@include media($mc2-unbr) {
            flex-wrap: wrap;
            > div {
              width: 100%;
              line-height: 2rem;
            }
          }*/
        }

        &-price {
          font-weight: $bold;
          color: $blue;
        }

        &:hover {
          background-color: $whitesmoke;
        }
      }
      &-promo-btn {
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        justify-content: center;
        //align-items: center;
      }
    }
    .mc2-block-2 {
      background-color: $white;
      padding: 0 2rem;
      font-size: 0.875rem;
      line-height: 1rem;

      .mc2-block-2-a{
        padding-bottom: 2rem;
      }

      .block-h1 {
        line-height: 3rem;
      }
      .text-block-14 {
        height: 3rem;
      }
      .block-h2,
      .text-block-14,
      .justify{
        margin-bottom: 1rem;
      }

      .mc2-block-2-img {
        @include size(100% 4rem);
        background: url('../img/images/2.jpg') center no-repeat;
        background-size: cover;
        margin-bottom: 1rem;
      }

      .mc2-block-2-date {
        font-weight: $lite;
      }

      .mc2-block-2-b {
        .mc2-block-2-img {
          @include size(100% 4rem);
          background: url('../img/images/3.jpg') center no-repeat;
          background-size: cover;
        }
        .mc2-block-2-btn {
          margin-bottom: 1rem;
          display: flex;
          justify-content: center;
        }
      }
    }
    .mc2-block-3 {
      &-1{
        padding: 0 2rem;
        background-color: $white;
        margin-bottom: 2rem;

        .block-h1 {
          line-height: 3rem;
          padding: 0;
        }
        .text-block-14 {
          height: 6rem;
        }
        .mc2-block-3-social {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 1rem;

          .mc2-block-3-ava {
            @include size(4rem);
            background: url('../img/icons/avatar_boykodmitriy.png') center no-repeat;
            background-size: contain;
          }
          .mc2-block-3-social-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding-left: 1rem;
            .text-block-14 {
              height: 1rem;
            }
            .justify {
              align-items: flex-end;
            }
            .social-link {
              height: 1rem;
              margin-right: 1rem;
            }
          }
        }
      }

      &-2 {
        margin-bottom: 2rem;
        padding: 0 2rem;
        background-color: $white;
        .block-h1 {
          line-height: 1.5rem;
          padding-top: 1rem;
          padding-bottom: 1rem;
          padding-left: 0;
          padding-right: 0;
          height: auto;
        }

        .text-block-12 {
          //line-height: 1rem;
          padding-bottom: 1rem;
        }
        .mc2-block-2-btn {
          padding-bottom: 1rem;
          display: flex;
          justify-content: center;
        }
      }
    }
    .mc2-block {
      margin-top: 2rem;
      @include span-columns(4);
      @include media($pc) {
        @include span-columns(6 of 12);
        @include omega(2n);
        &:last-child {
          //margin-top: 2rem;
          @include span-columns(12 of 12);
          @include omega();
          .mc2-block-3-1 {
            margin-bottom: 2rem;
          }
        }
        @include media($tablet) {
          @include span-columns(10 of 10);
          @include omega();
        }
      }
    }
  }
}

.main-container-3 {
  @include outer-container();
  //font-size: 0;
  min-width: $uncompressed;
  //padding-top: 1rem;
  //padding-bottom: 2rem;
  padding: 1rem 2rem 2rem 2rem;

  @include media($tablet) {
    padding: 1rem 0.5rem 2rem 0.5rem;
  }

  .mc3-block-1 {
    padding-bottom: 1.5rem;
    .block-h1 {
      padding-bottom: 1rem;
      line-height: 2rem;
    }
    .cell-1 {
      @include size(100% 4rem);
      margin-bottom: 1rem;
      padding-right: 2rem;
    }
    .cell-2,
    .cell-3,
    .cell-4 {
      @include size(100% 2rem);
      padding-left: 2rem;
      margin-bottom: 1rem;
      background: url('../img/icons/icon_check.png') center left no-repeat;
      //background-position: center left;
    }
    .cell-4 {
      @include size(100% 3rem);
    }
    .cell-5 {
      margin-bottom: 1rem;
      .cross-link-blue {
        line-height: 1rem;
      }
    }
    @include span-columns(6);
    @include media($header-unbreakable) {
      @include span-columns(12);
      @include omega();
    }
  }

  .mc3-block-2 {
    @include span-columns(6);
    @include omega();
    @include media($laptop) {
      @include span-columns(12);
      @include omega();
    }
    .mc3-block-2-1 {
      @include span-columns(3 of 6);
      @include media($laptop) {
        @include span-columns(6 of 12);
        @include media($mobile) {
          @include span-columns(4);
        }
      }
    }
    .mc3-block-2-2 {
      @include span-columns(3 of 6);
      @include omega();
      @include media($laptop) {
        @include span-columns(6 of 12);
        @include omega();
        @include media($mobile) {
          @include span-columns(4);
          @include omega();
        }
      }
    }
    padding-bottom: 1.5rem;
    .text-h1 {
      line-height: 2rem;
      vertical-align: middle;
      padding-bottom: 0.5rem;
    }
    .cell-11,
    .cell-12,
    .cell-13,
    .cell-14,
    .cell-15,
    .cell-17,
    .cell-18,
    .cell-19,
    .cell-20,
    .cell-21{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 3rem;
      line-height: 1rem;
      padding-left: 3rem;
      background: url('../img/icons/mini/icon_workstation-.png') center left 0.5rem no-repeat;
      &:hover {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
      }
    }
    .cell-12 {
      background: url('../img/icons/mini/icon_monoblock.png') center left 0.5rem no-repeat;
    }
    .cell-13 {
      background: url('../img/icons/mini/icon_laptop-.png') center left 0.5rem no-repeat;
    }
    .cell-14 {
      background: url('../img/icons/mini/icon_tablet-.png') center left 0.75rem no-repeat;
    }
    .cell-15 {
      background: url('../img/icons/mini/icon_ebook-.png') center left 0.875rem no-repeat;
    }
    .cell-17 {
      background: url('../img/icons/mini/icon_apple-monitor-.png') center left 0.5rem no-repeat;
    }
    .cell-18 {
      background: url('../img/icons/mini/icon_cellphone-.png') center left 0.875rem no-repeat;
    }
    .cell-19 {
      background: url('../img/icons/mini/icon_monitor.png') center left 0.5rem no-repeat;
    }
    .cell-20 {
      background: url('../img/icons/mini/1.png') center left 0.5rem no-repeat;
    }
    .cell-21 {
      background: url('../img/icons/mini/icon_building-.png') center left 0.5rem no-repeat;
      margin-bottom: 1rem;
    }
    .cell-16 {
      .cross-link-blue {
        line-height: 1rem;
      }
    }
  }

  .mc3-block-4 {
    @include span-columns(12);
    @include omega();
    padding: 1rem 2rem;
    .block-h1 {
      line-height: 2rem;
    }
  }
}