
/*body
  margin: 10px
  background-color: $silver
  overflow-x: hidden */

/*.page-home
  text-align: center

  section
    max-width: 600px
    margin: 50px auto
    padding: 40px
    position: relative
    background-color: rgba(255,255,255,0.7)
    box-shadow: 0px 2px 10px rgba(0,0,0,0.1)

    &:first-child
      background:
        image: url(../img/tools.png)
        position: center

    h1
      font-weight: 700
      line-height: 1
      margin: 20px 0
      letter-spacing: 1px

  img
    max-width: 100%

  .flex-video-wrap
    max-width: 640px
    display: inline-block
    width: 100%

  pre
    text-align: left
    font-family: 'Fira Mono', monospace
    font-size: 30px
    color: darken($silver, 5%)
    position: absolute
    top: -50px
    left: -50px
    z-index: -1
    pointer-events: none

    @media (max-width: 500px)
      font-size: 20px

  article
    margin-bottom: 50px

    &:last-child
      margin-bottom: 0px*/
