@charset "UTF-8";

/// Disable all deprecation warnings. Defaults to `false`. Set with a `!global` flag.
///
/// @type Bool

$disable-warnings: false !default;

@mixin -neat-warn($message) {
  @if $disable-warnings == false {
    @warn "#{$message}";
  }
}
