@charset "UTF-8";

/// Changes the direction property used by other mixins called in the code block argument.
///
/// @param {String} $direction [left-to-right]
///   Layout direction to be used within the block. Can be `left-to-right` or `right-to-left`.
///
/// @example scss - Usage
///   @include direction-context(right-to-left) {
///    .right-to-left-block {
///      @include span-columns(6);
///     }
///   }
///
/// @example css - CSS Output
///   .right-to-left-block {
///     float: right;
///      ...
///   }

@mixin direction-context($direction: left-to-right) {
  $scope-direction: $layout-direction;

  @if to-lower-case($direction) == "left-to-right" {
    $layout-direction: LTR !global;
  } @else if to-lower-case($direction) == "right-to-left" {
    $layout-direction: RTL !global;
  }

  @content;

  $layout-direction: $scope-direction !global;
}
