$form-fixer: new-breakpoint(max-width 1212px 12);
.comments-header {
  &-text {
    font-size: 2.875rem;
    color: $blue;
    margin-bottom: 1rem;
  }
  &-img {
    max-height: 9.5rem;
    //@include size(auto);
  }
}
.comments-cell {
  //Позиционирование
  //Блочная модель
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 2rem;
  margin-top: 1rem;
  //min-height: 17rem;
  &:last-child {
    margin-bottom: 1rem;
  }
  &-original {
    min-height: 17rem;
  }
  &-small {
    min-height: 13rem;
  }
  // Типографика
  // Оформление
  background: $white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  &-header {
    display: flex;
    //margin-top: 0.75rem;
    margin-bottom: 1rem;
    &-avatar {
      margin-right: 1rem;
      @include size(4rem);
    }
    &-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      &-text {
        color: $text;
        font-size: 0.75rem;
      }
      &-name {
        font-size: 1rem;
        font-weight: 700;
        color: $text;
      }
      &-link {
        color: $blue;
        font-size: 0.75rem;
      }
      &-footer {
        display: flex;
        align-items: flex-end;
        &-vk {
          @include size(1.5rem 0.875rem);
          margin-right: 1rem;
        }
        &-time {
          color: $text;
          font-size: 0.75rem;
        }
      }
    }
  }
  &-notation {
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $text;
    margin-bottom: 0.75rem;
  }
  &-text {
    color: $text;
    font-size: 0.875rem;
  }
  &-input {
    display: block;
    margin-bottom: 1rem;
    @include size(100% 6rem);
    &-text {
      @include size(100% 6rem);
      border: 1px solid $blue-hover;
      padding: 1rem;
      color: $text;
      font-size: 0.875rem;
    }
  }
}
.comments-carousel-bar {
  @include size(100%);
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  &-button {
    @include size(3rem 2rem);
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
    &-arrow {
      transition: all 0.3s ease;
      text-align: center;
      &-img {
        height: 100%;
      }
      &:hover {
        opacity: 0.5;
        background: $blue;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
      }
    }
    &-num {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: $whitesmoke-c;
      color: $white;
      font-size: 1.125rem;
      font-weight: 700;
      transition: all 0.3s ease;
      &:hover {
        //opacity: 0.5;
        background: $blue;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
        color: $white;
      }
    }
    &-active {
      background: $blue;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
      color: $white;
    }
  }
}

@include media($form-fixer) {
  .comments-cell-notation {
    margin-bottom: 1.875rem;
  }
  @include media($footer-unbr) {
    .comments-header-img {
      display: none;
    }
  }
}