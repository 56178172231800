$pc: new-breakpoint(max-width 1280px 12);
$header-unbreakable: new-breakpoint(max-width 1024px 12);
$laptop: new-breakpoint(max-width 1024px 12);
$tablet-h: new-breakpoint(max-width 780px 10);
$tablet: new-breakpoint(max-width 768px 10);
$tablet-s: new-breakpoint(max-width 568px 4);
$h-phone-unbreakable: new-breakpoint(max-width 512px 4);
$mobile: new-breakpoint(max-width 480px 4);
$nth: 1;
@mixin omega-reset($nth, $context: null) {
  @if $context == null {
    &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  } @else {
    &:nth-child(#{$nth}) { margin-right: flex-gutter($context); }
  }
  &:nth-child(#{$nth}+1) { clear: none; }
}

.header-all {
  font-size: 0;
  min-width: $uncompressed;
  //Версия от 17-06-16 Начало

  .triangle-all {
    height: 2rem;
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    margin-left: 1rem;
    .triangle-5 {
      border: 0.5rem solid transparent; /* Прозрачные границы */
      border-top: 0.5rem solid $text; /* Добавляем треугольник */
    }
  }
  .h-top-bg {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 102;
    background-color: $gray-header-bg;
    font-size: 1rem;
    font-weight: $normal;
    color: $text;
    white-space: nowrap;
    //min-height: 2rem;
    .h-button-mobile {
      display: none; //Кнопка скрывается при $tablet-s
      cursor: pointer;
      position: relative; //v-menu
      &-block {
        //margin-right: 1rem;
        //padding: 0.5rem 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        height: 2rem;
        padding: 0.5rem 1rem;
        &-line {
          width: 1.5rem;
          height: 0.125rem;
          background: $gray-copyright;
        }
      }
      &-text {
        margin-right: 1rem;
        line-height: 1rem;
        font-size: 1rem;
        font-weight: $bold;
        text-transform: uppercase;
      }
      &-normalizer {

      }
      .v-top-menu {
        //экспирементальная функция
        opacity: 0; /* По умолчанию скрываем подменю */
        visibility: hidden;
        transition: all 0.3s ease 0.3s; /* Добавляем анимацию 0.3 сек. и задержку в 1 сек. */
        //
        position: absolute;

        background-color: $white;
        z-index: 103;
        box-shadow: 0 6px 6px rgba(0, 0, 0, 0.6);
        font-size: 1rem;
        color: $text;
        .v-button {
          display: block;
          .h-button-normalizer {
            padding: 0 2.5rem;
            height: 3rem;
            border-bottom: 1px solid $whitesmoke-c;
          }
          &:hover {
            background-color: $blue-hover;
            color: $text;
            .h-button-normalizer {
              border: none;
            }
          }
        }
        .h-service-menu {
          top: 0;
        }
      }
      &:hover {
        .v-top-menu {
          opacity: 1; /* Показываем подменю при ховере */
          visibility: visible;
          //border: 1px solid red;
        }
      }
      @include media($tablet-h) {
        display: block;
        .v-top-menu {
        }
      }
    }
    .h-top-menu {
      @include media($tablet-h) {
        display: none;
      }
      //не заполненно
    }
    .s-button {
      //transition: all 1s ease;
      &:hover {
        .h-service-menu {
          //display: block;
          opacity: 1; /* Показываем подменю при ховере */
          visibility: visible;
        }
      }
    }
    .h-button {
      position: relative;
      display: inline-block;
      //width: 100%;
      //padding: 0 1rem;
      transition: all $fast ease;

      font-size: 1rem;
      font-weight: $normal;
      color: $text;
      &:hover {
        background-color: $blue-hover;
        color: $text;
      }
    }

    .h-service-menu {
      //меню
      //display: none;
      //экспирементальная функция
      opacity: 0; /* По умолчанию скрываем подменю */
      visibility: hidden;
      transition: all 0.3s ease 0.5s; /* Добавляем анимацию 0.3 сек. и задержку в 1 сек. */
      //
      position: absolute;
      background-color: $white;
      z-index: 103;
      box-shadow: 0 6px 6px rgba(0, 0, 0, 0.6);
      .h-service-button {
        //z-index: 102;
        //position: absolute;
        display: block;
        //padding: 0 1rem;
        .h-button-normalizer {
          height: 3rem;
          border-bottom: 1px solid $whitesmoke-c;
        }
        &:hover {
          background-color: $blue-hover;
          .h-button-normalizer {
            border-bottom: 1px solid $blue-hover;
          }
        }
      }
    }
  }

  //Версия от 17-06-16 Окончание
  .header-top-side {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 102;
    background-color: $gray-header-bg;
    min-height: 2rem;
    span {
      //display: inline-block;
      font-size: 1rem;
      font-weight: $normal;
      color: $text;
      white-space: nowrap;
    }
    .h-menu {
      //display: inline-block;
      font-size: 1rem;
      font-weight: $normal;
      color: $text;
      white-space: nowrap;
    }

    .header-button-mob {
      display: none;

      cursor: pointer;
      //padding: 0.5rem 1rem;
      padding: 0.5rem 1rem;

      .header-menu-button-block {
        margin-right: 1rem;
        //padding: 0.5rem 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        height: 1rem;
        .header-menu-button-line {
          width: 1.5rem;
          height: 0.125rem;
          background: $gray-copyright;
        }
      }
    }
    .header-menu-list{
      //display: flex;
      //justify-content: flex-start;
      //align-items: center;
      //Рабочая версия
    }
    .header-button {
      //position: relative;
      display: inline-block;
      //width: 100%;
      //padding: 0 1rem;
      transition: all $fast ease;
      color: $text;
      &:hover {
        background-color: $blue-hover;
        color: $text;
        //border: 1px solid red;

      }
      //вынесено вверх
      /*.h-button-normalizer {

        height: 2rem;
        display: flex;
        align-items: center;
      }*/
    }
    //

//
    .h-menu-c {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include media($tablet-h) {
        .header-menu-list {
          display: none;
        }
        .header-button-mob {
          display: inline-block;
          background-color: white;
        }
        &:hover {
          flex-direction: column;
          .header-button-mob {
            display: inline-block;
            background-color: $blue-hover;
          }
          .header-menu-list {
            position: absolute;
            right: 0;
            left: 0;
            //@include span-columns(10 of 10);
            //@include omega();
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .header-button {
              width: 100%;
              padding-left: 4rem;
              background: $whitesmoke;
              border-bottom: 1px solid $gray-header-bg;
              &:last-child {
                border-bottom: none;
              }
              &:hover {
                background-color: $blue-hover;
              }
            }
          }
        }
      }
    }
  }
  .h-button-normalizer {
    height: 2rem;
    display: flex;
    //flex-direction: inherit;
    align-items: center;
    //border-bottom: 1px solid $whitesmoke;
    margin: 0 1rem;
    color: $text;
    justify-content: space-between;
  }


  /*.h-service-active {
    display: none;
    &:hover {

    }
  }*/
  //Рабочая версия ниже
  /*
  .h-box-out {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include media($tablet-h) {
    //align-items: flex-start;
    justify-content: flex-end;
  }
}
.h-box-in {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 2rem;
  @include media($tablet-h) {
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    left: 0;
    right: 0;
    .active_page {
      order: -1;
    }
  }
  //Размещен ниже
  &:hover {
    .header-button {
      display: inline-block;
      z-index: 103;
    }
  }
}
.header-button-m {
  @include media($tablet-h) {
    @include span-columns(5 of 10);
    @include omega();
    @include media($tablet-s) {
      @include span-columns(10 of 10);
      @include omega();
    }
    .h-button-normalizer {
      padding-left: 2.5rem;
    }
  }
}

  .header-top-side {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 102;
    background-color: $gray-header-bg;
    height: 2rem;
    font-size: 0;

    .header-menu {
      min-height: 2rem;
      min-width: $uncompressed;
      @include media($tablet-h) {
        padding: 0 0.5rem;
      }
      .header-button {
        min-height: 2rem;
        font-size: 1rem;
        color: $text;
        display: inline-block;
        padding-left: 1rem;
        padding-right: 1rem;
        transition: all $fast ease;
        &:hover {
          background-color: $blue-hover;
        }
        .h-button-normalizer {
          min-height: 2rem;
          display: flex;
          align-items: center;
        }
        .header-button-mob {
          width: 1.5rem;
          margin-right: 1rem;
          display: none;
          cursor: pointer;
          .header-menu-button-block {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            height: 1rem;
            .header-menu-button-line {
              width: 100%;
              height: 0.125rem;
              background: $gray-copyright;
            }
          }
          @include media($tablet-h) {
            display: inline-block;
          }
        }
        @include media($tablet-h) {
          display: none;
          border-bottom: 1px $whitesmoke solid;
          background: $white;
          &:first-child {
            display: inline-block;
            //border: none;
          }
          &:last-child {
            //border: none;
          }
        }
      }
      .h-box-in:hover {
        .header-button {
          display: inline-block;
          z-index: 103;
        }
      }

    }
  }*/

  .header-bottom-side {
    min-width: $uncompressed;
    width: 100%;
    padding: 1rem 2rem;
    @include outer-container();
    @include media($tablet) {
      padding: 1rem 0.5rem;
    }
    .header-infocell-1 {
      @include span-columns(4);
      @include media($laptop) {
        @include span-columns(12);
        @include omega();
        margin-bottom: 1rem;
        .justify {
          justify-content: center;
        }
      }
    }
    .header-infocell-2 {
      @include span-columns(4);
      @include media($laptop) {
        @include span-columns(6);
        @include media($tablet-s) {
          @include span-columns(4);
          @include omega();
          margin-bottom: 1rem;
        }
      }
    }
    .header-infocell-3 {
      @include span-columns(4);
      @include omega();
      @include media($laptop) {
        @include span-columns(6);
        .justify {
          justify-content: center;
        }
        @include media($tablet-s) {
          @include span-columns(4);
          @include omega();
        }
      }
    }

    .header-infocell {
      height: $logo;
      line-height: 1.5rem;
      //color: $text;
      .h-logo {
        @include size(17rem 3.125rem);
        background: url('../img/logo/logo.png') center no-repeat;
        background-size: contain;
      }
      .h-phone {
        color: $text; //ie edge
        line-height: 2rem;
        font-size: 2rem;
        font-weight: $bold;
      }
      .h-service {
        display: inline-block;
        color: $aquamarine;
        font-size: 1rem;
        line-height: 1rem;
        padding-left: 1.5rem;
        background: url('../img/icons/used/icon_phone.png') left no-repeat;
      }
      .h-cell-3,
      .h-cell-4 {
        display: inline-block;
        text-align: left;
        font-size: 0.875rem;
        color: $text;
        padding-left: 2.5rem;
        line-height: 1.5rem;
        background: url('../img/icons/used/icon_time.png') left no-repeat;
      }
      .h-cell-4 {
        line-height: 0.8125rem; //($logo-1.5rem)/2
        background: url('../img/icons/used/icon_map.png') left no-repeat;
      }
    }
  }
}

//Ниже альтернаьтивный вариант, централизированное задание контрольных точек.
//Не работает вложение по изменению шрифтов.

/*.header-all {
  @include media($header-unbreakable) {
     .header-infocell:first-child {
       @include span-columns(12);
       @include omega(12);
       .start {
         justify-content: center;
       }
       margin-bottom: 1rem;
     }
     .header-infocell:nth-child(2) {
       @include span-columns(6);
     }
     .header-infocell:last-child {
       @include span-columns(6);
       @include omega();
       .end {
         justify-content: center;
       }
     }
   }
  @include media($tablet) {
    .header-top-side {
      padding: 0;
      .header-menu-bar {
        @include span-columns(10);
        @include omega();
        .header-button {
          font-size: 0.875rem;
          padding-left: 0.25rem;
          padding-right: 0.25rem;
        }
      }
    }
  }
  @include media($h-phone-unbreakable) {
    .h-phone {
      font-size: 1.85rem;
    }
  }

@include media($mobile) {
  .header-infocell {
    @include span-columns(8);
    @include omega-reset(12);
    @include omega(8);
  }
}

  color: $text;
  min-width: $uncompressed;
  .header-top-side {
    //min-width: $uncompressed;
    background-color: $gray-header-bg;
    padding: 0 2rem;

    height: 2rem;
    font-size: 0;
    .header-menu {
      @include outer-container;

      .header-menu-bar {
        @include span-columns(12);
        @include omega();
        @include media($pc) {
          @include span-columns(12);
          @include omega();
        }

        .header-button {
          min-height: 2rem;
          line-height: 2rem;
          font-size: 1rem;
          color: $text;
          display: inline-block;
          padding-left: 1rem;
          padding-right: 1rem;
          transition: all $fast ease;

          &:hover {
            background-color: $blue-hover;
          }
        }
      }
    }
  }
  .header-bottom-side {

    @include outer-container();
    //min-width: $uncompressed;
    padding: 1rem 2rem;

    .header-infocell {
      @include span-columns(4);
      @include omega(3n);

      height: $logo;
      line-height: 1.5rem;

      .h-logo {
        @include size(17rem 3.125rem);
        background: url('../img/logo/logo.png') center no-repeat;
      }
      .h-phone {
        color: $text; //ie edge
        line-height: 2rem;
        font-size: 2rem;
        font-weight: $bold;
      }
      .h-service {
        display: inline-block;
        color: $aquamarine;
        font-size: 1rem;
        line-height: 1rem;
        padding-left: 1.5rem;
        background: url('../img/icons/used/icon_phone.png') left no-repeat;
      }
      .h-cell-3,
      .h-cell-4 {
        display: inline-block;
        text-align: left;
        font-size: 0.875rem;
        color: $text;
        padding-left: 2.5rem;
        line-height: 1.5rem;
        background: url('../img/icons/used/icon_time.png') left no-repeat;
      }
      .h-cell-4 {
        line-height: 0.8125rem; //($logo-1.5rem)/2
        background: url('../img/icons/used/icon_map.png') left no-repeat;
      }
    }
  }
}
*/